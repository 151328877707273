<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    page: {
        title: "Validasi Materi Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Nomor Surat", name: "-" },
            { width: "100px", label: "Skema Sertifikasi", name: "-" },
            { width: "100px", label: "Metode", name: "-" },
            { width: "125px", label: "Asesor Validator", name: "-" },
            { width: "150px", label: "Dibuat Tanggal", name: "-" },
            { width: "150px", label: "Diubah Tanggal", name: "-" },
            { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Validasi Materi Uji Kompetensi",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Daftar Validasi Materi Uji Kompetensi",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id_menu_master", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
                skema_id: null,
                asesor_id: null
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            // variable filter
            optionsSkemaSertifikasi: [],
            optionsAsesor: [],
            selectedSkemaSertifikasi: null,
            selectedAsesor: null,
        };
    },
    mounted() {
        this.getDataTable();
        this.getSkemaSertifikasi();
        this.getAsesor();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        toPage: function (url) {
            let self = this;
            self.getDataTable(url);
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/all-validasi") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        if (self.tableData.draw == response_data_fix.draw) {
                            self.table_data = response_data_fix.list_data.data;
                            self.configPagination(response_data_fix.list_data);
                            self.currentTablePage = response_data_fix.list_data.current_page;
                            self.loadingTable = false;
                        }
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        configPagination(data) {
            console.log(data);
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        ubahStatus(id) {
            console.log(id);
            var alert_text = "Apakah Anda yakin untuk mengubah status?";
            Swal.fire({
                title: "Peringatan !",
                text: alert_text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                } else {
                    Swal.close();
                }
            });
        },
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI 
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAsesor() {
            let self = this;
            // load data
            var config_user = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_user)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesor = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        filterDatatable() {
            let self = this;
            self.tableData.skema_id = self.selectedSkemaSertifikasi?.id_skema_sertifikasi;
            self.tableData.asesor_id = self.selectedAsesor?.pegawai_id;
            self.getDataTable();
        },
        exportPdf(){
            let self = this;
            if (self.selectedSkemaSertifikasi != null) {
                self.tableData.skema_id = self.selectedSkemaSertifikasi?.id_skema_sertifikasi;
            }
            if (self.tableData.asesor_id) {
                self.tableData.asesor_id = self.selectedAsesor?.pegawai_id;
            }
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/pdf-validasi",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                responseType: 'blob',
            };
            axios(config)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: 'application/pdf' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Daftar_Validasi_Materi_Uji_Kompetensi.pdf'
                    link.click()
                })
                .catch(function (error) {
                    console.log(error);
                });  
        },
        exportExcel() {
            let self = this;
            var skema_id = ''
            if(self.selectedSkemaSertifikasi?.id_skema_sertifikasi){
                skema_id = self.selectedSkemaSertifikasi?.id_skema_sertifikasi;
            }
            
            var asesor_id = "";
            if (self.tableData.asesor_id) {
                asesor_id = self.selectedAsesor?.pegawai_id;
            }
            const link = document.createElement("a");
            link.href = process.env.VUE_APP_BACKEND_URL_VERSION + "public/penyusunan-muk/excel-validasi?skema_sertifikasi_id=" + skema_id + "&asesor_id=" + asesor_id + "&user_id=" + localStorage.session_user_id+ "&pegawai_id=" + localStorage.session_pegawai_id;
            link.setAttribute("target", "_blank");
            link.click();
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="statusTable"> Pilih Skema Sertifikasi </label>
                                        <v-select :options="optionsSkemaSertifikasi" label="nama_skema_sertifikasi" v-model="selectedSkemaSertifikasi" placeholder="Pilih Skema Sertifikasi"></v-select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="statusTable"> Asesor </label>
                                        <v-select :options="optionsAsesor" label="nama_asesor" v-model="selectedAsesor" placeholder="Pilih Asesor"></v-select>
                                    </div>
                                    <div class="form-group col-md-2 mt-4">
                                        <button class="btn btn-info btn-sm" @click="filterDatatable"><i class="fas fa-search"></i> Cari Data</button>
                                    </div>
                                    <div class="form-group col-md mt-4 text-end">
                                        <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i> Cetak Excel</button>
                                        <button class="btn btn-sm btn-danger m-1" @click="exportPdf"><i class="fas fa-file-pdf"></i> Cetak PDF</button>
                                        <!-- <router-link :to="{ name: 'add-surat_tugas_penyusunan' }" class="btn btn-sm btn-primary m-1" v-if="sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1' || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'"><i class="fa fa-plus"></i> Tambah Materi Uji Kompetensi</router-link> -->
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                                    </div>
                                </div>
                                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                    <tbody>
                                        <tr v-if="loadingTable">
                                            <td colspan="9"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                        </tr>
                                        <tr v-else-if="table_data == ''">
                                            <td class="text-center" colspan="9">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ ((parseInt(tableData.length) * parseInt(currentTablePage)) - parseInt(tableData.length)) + key_data + 1}}.
                                            </td>

                                            <td>
                                                {{ row_data.surat_validasi_nomor }}
                                            </td>
                                            <td>
                                                {{ row_data.skema_name }}
                                            </td>
                                            <td>
                                                {{ row_data.metode_sertifikasi_nama }}
                                            </td>
                                            <td>
                                                <span v-if="row_data.asesor_validator">
                                                    <ul>
                                                        <li v-for="(row_asesor, key_asesor) in row_data.asesor_validator" :key="key_asesor">
                                                            {{ row_asesor?.asesor_nama }}
                                                        </li>
                                                    </ul>
                                                </span>
                                                <span v-else> Data Tidak Tersedia </span>
                                            </td>
                                            <td>
                                                {{ fullDateTimeFormat(row_data.created_at) }}
                                            </td>
                                            <td>
                                                {{ fullDateTimeFormat(row_data.updated_at) }}
                                            </td>
                                            <td>
                                                {{ row_data.status_nama }}
                                                <div class="text-danger" v-if="row_data.notes">ALasan Penolakan : {{ row_data.notes }}</div>
                                            </td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link
                                                        :to="{
                                                            name: 'detail-penyusunan',
                                                            params: { id: row_data.penyusunan_muk_id },
                                                        }"
                                                        class="btn btn-sm btn-primary bg-primary">
                                                        <i class="fas fa-search"></i>
                                                        Detail Validasi
                                                    </router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </datatable>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
